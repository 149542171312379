import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultLayoutComponent, SessionLayoutComponent } from './containers';
import { AuthorizeGuard } from './shared/guards/authorize.guard';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'session/login' },
  {
    path: 'page',
    component: DefaultLayoutComponent,
    canActivate: [AuthorizeGuard],
    children: [
      {
        path: 'dashboard',
        data: { breadcrumb: 'Dashboard' },
        loadChildren: () =>
          import('./pages/dashboard/dashboard.module').then(
            (m) => m.DashboardModule
          ),
      },
      {
        path: 'parametres',
        data: { breadcrumb: 'Parametres' },
        loadChildren: () =>
          import('./pages/parametres/parametres.module').then(
            (m) => m.ParametresModule
          ),
      },
      {
        path: 'catalogue',
        data: { breadcrumb: 'Catalogue' },
        loadChildren: () =>
          import('./pages/catalogue/catalogue.module').then(
            (m) => m.CatalogueModule
          ),
      },
      {
        path: 'statistiques',
        loadChildren: () =>
          import('./pages/statistiques/statistiques.module').then(
            (m) => m.StatistiquesModule
          ),
      },
      {
        path: 'planning',
        loadChildren: () =>
          import('./pages/planning/planning.module').then(
            (m) => m.PlanningModule
          ),
      },
      {
        path: 'team',
        loadChildren: () =>
          import('./pages/team/team.module').then((m) => m.TeamModule),
      },
      {
        path: 'terrain',
        loadChildren: () =>
          import('./pages/terrain/terrain.module').then((m) => m.TerrainModule),
      },
      {
        path: 'decoupages',
        loadChildren: () =>
          import('./pages/decoupages/decoupages.module').then(
            (m) => m.DecoupagesModule
          ),
      },
      {
        path: 'masterboard',
        loadChildren: () =>
          import('./pages/masterboard/masterboard.module').then(
            (m) => m.MasterboardModule
          ),
      },
    ],
  },
  {
    path: 'session',
    component: SessionLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./pages/login/login.module').then((m) => m.LoginModule),
      },
    ],
  },

  {
    path: '**',
    redirectTo: 'session/login',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
