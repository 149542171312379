import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PaginatorPipe } from './pipes/paginator.pipe';
import { WrapperFunctionPipe } from './pipes/wrapperFunction.pipe';
import { CanAccessPipe } from './pipes/access.pipe';

@NgModule({
  declarations: [PaginatorPipe, WrapperFunctionPipe, CanAccessPipe],
  imports: [CommonModule],
  exports: [PaginatorPipe, WrapperFunctionPipe, CanAccessPipe],
})
export class SharedModule {}
