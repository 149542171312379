import { Role } from 'src/app/shared/models/Role.model';
import { Component, OnInit } from '@angular/core';
import { navItems } from '../../_nav';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService } from 'ngx-envconfig';
import { AuthentificationService } from 'src/app/shared/services/authentification.service';
import { Utilisateur } from '../../shared/models/Utilisateur.model';
@Component({
  selector: 'app-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent implements OnInit {
  menus = [];

  isCollapsed = false;
  connUser: Utilisateur;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private configService: ConfigService,
    private auth: AuthentificationService
  ) {
    this.connUser = JSON.parse(localStorage.getItem('smt_user'));
  }

  ngOnInit(): void {
    this.menus = navItems;
    this.menus = this.auth.getAuthMenuItems(
      JSON.parse(JSON.stringify(navItems))
    );
  }

  versProfil() {
    this.router.navigate(['/page/parametres/profile']);
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/']);
  }

  getImg(src: string) {
    if (!src) {
      return 'assets/img/logo.png';
    }
    return src.replace(
      this.configService.get('imgVar'),
      this.configService.get('imgHttp')
    );
  }
}
