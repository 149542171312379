import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paginator',
  pure: true,
})
export class PaginatorPipe implements PipeTransform {
  transform(value: any, column: string): any {
    switch (column) {
      case 'content':
        return value && value['content'] ? value['content'] : [];
        break;
      case 'page':
        return value && value['number'] ? value['number'] + 1 : 1;
        break;
      case 'size':
        return value && value['size'] ? value['size'] : 0;
        break;
      case 'total':
        return value && value['totalElements'] ? value['totalElements'] : 0;
        break;
      default:
        return 0;
        break;
    }
  }
}
