import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'wrapper',
  pure: true,
})
export class WrapperFunctionPipe implements PipeTransform {
  transform(value: any, funct, event): any {
    return funct(event);
  }
}
