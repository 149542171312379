import { ConfigService } from 'ngx-envconfig';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { LoginObject } from '../models/LoginObject.model';
import { Utilisateur } from '../models/Utilisateur.model';

import { SocialAuthService } from 'angularx-social-login';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AuthentificationService {
  private _data = new BehaviorSubject<any>({});
  public _perm = new BehaviorSubject<any>({});
  public _flatNav = new BehaviorSubject<any>({});

  public set user(value) {
    //this._data = value
    this._data.next(value);
  }
  public get user() {
    return this._data.getValue();
  }

  public set userPerm(value: any[]) {
    //this._data = value
    this._perm.next(value);
  }
  public get userPerm() {
    return this._perm.getValue();
  }

  public set userFlatNav(value: any[]) {
    //this._data = value
    this._flatNav.next(value);
  }
  public get userFlatNav() {
    return this._flatNav.getValue();
  }

  role: any = this.configService.get('role');

  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private router: Router,
    private authService: SocialAuthService
  ) {}

  public checkUser() {
    return this._data;
  }

  public login(connexion: LoginObject): Observable<HttpResponse<any>> {
    return this.http.post(
      this.configService.getApi('USER_CONNECT'),
      connexion,
      {
        observe: 'response',
      }
    );
  }

  public logout(): void {
    this.removeUserToLocalStorage();
  }

  addUserToLocalStorage(user: any) {
    console.log(user);
    user = user as Utilisateur;
    if (localStorage.getItem('smt_user') != null) {
      localStorage.removeItem('smt_user');
      localStorage.setItem('smt_user', JSON.stringify(user));
      console.log(localStorage.getItem('smt_user'));
    } else {
      localStorage.setItem('smt_user', JSON.stringify(user));
    }
  }

  getUser(): Utilisateur | null {
    let dt = localStorage.getItem('smt_user');
    if (dt != null) {
      return JSON.parse(dt);
    } else {
      this.router.navigate(['/']);
      return null;
    }
  }

  /**
   * Permet d'obtenir le code de la permission dun user sur une interface
   */
  getpermissionIcode(permissionlibelle) {
    let val = this.userPerm.filter((p) =>
      p.permissionlibelle.startsWith(permissionlibelle)
    );
    return val[0].permissioncode;
  }

  removeUserToLocalStorage() {
    //this.authService.signOut(false)
    localStorage.removeItem('smt_user');
  }

  //  =================================================================================================

  getAuthMenuItems(navItems) {
    let connUser = JSON.parse(localStorage.getItem('smt_user'));
    let permissions = [];
    try {
      permissions = JSON.parse(atob(connUser.role.roledescription)); // recuperer la chaine criptée de permissions
    } catch (error) {
      console.log(error);
      permissions = [];
    }
    console.log('Permissions :::: ', permissions);

    return this.getAuthMenuV2(
      permissions || [],
      JSON.parse(JSON.stringify(navItems))
    );
  }

  getAuthMenuV2(permission: any[], navItems: any[]) {
    console.log(` ${permission} |||||`);
    let acc = [];
    if (!permission) {
      return [];
    }
    if (permission == undefined) {
      return [];
    }
    if (permission.length == 0) {
      return [];
    }
    for (let ind = 0; ind < navItems.length; ind++) {
      const curr = navItems[ind];

      if (!curr['children']) {
        //si on a pas de sous menu
        if (ind < permission.length) {
          if (this.checkifhasglobalpermission(permission[ind])) {
            acc.push(curr);
          } //verifie si il a ce menu
        }
      } else {
        //si on a un children
        let child = this.getAuthMenuV2(
          permission[ind].children,
          curr['children']
        );
        if (child.length > 0) {
          curr['children'] = child;
          acc.push(curr);
        }
      }
    }
    return acc;
  }

  checkifhasglobalpermission(obj) {
    for (const key in obj.data) {
      if (obj.data[key] == true) return true;
    }
    return false;
  }

  //  =================================================================================================

  /**
   * Permet d'obtenir le statut de la permission dun user sur une interface
   */
  haveAccess(
    code: 'id' | 'create' | 'update' | 'delete' | 'level2' | 'level1' | 'other'
  ) {
    let connUser = JSON.parse(localStorage.getItem('smt_user'));
    let permissions = JSON.parse(atob(connUser.role.roledescription));
    console.log('====== URL');
    const url = window.location.href
      .split('/#/')[1]
      .replace('page/', '')
      .split('?')[0];
    console.log(url);
    console.log('====== PERMISSIONS');
    console.log(permissions);
    console.log('====== PERMISSIONS FLAT');
    console.log(this.flattenPermission(permissions));
    const element = this.getPermission(
      this.flattenPermission(permissions),
      url
    );
    console.log('Object found ==> ', element);
    //  check for full accesss
    const hasFullAccess = element['id'];
    if (hasFullAccess) return true;
    else {
      if (code == 'level2' || code == 'other') {
        if (element['level1']) {
          return true;
        }
      }
      return element[code];
    }
  }
  // hasId() {
  //   let connUser = JSON.parse(localStorage.getItem('smt_user'));
  //   let permissions = JSON.parse(atob(connUser.role.roledescription));
  //   console.log('====== URL');
  //   const url = window.location.href
  //     .split('/#/')[1]
  //     .replace('page/', '')
  //     .split('?')[0];
  //   console.log(url);
  //   console.log('====== PERMISSIONS');
  //   console.log(permissions);
  //   console.log('====== PERMISSIONS FLAT');
  //   console.log(this.flattenPermission(permissions));
  //   const element = this.getPermission(
  //     this.flattenPermission(permissions),
  //     url
  //   );
  //   console.log('Object found ==> ', element);
  //   //  check for full accesss
  //   const hasFullAccess = element['id'];
  //   if (hasFullAccess) return true;
  //   else return false;
  // }

  flattenPermission(perms: any) {
    let final = [];
    for (const permission of perms) {
      if (permission.children) {
        const { children, data } = permission;
        final.push(data);
        final.push(...children.map((child) => child.data));
      } else {
        final.push(permission.data);
      }
    }
    return final;
  }

  getPermission(flatperm: any, url: string) {
    for (let i = 0; i < flatperm.length; i++) {
      const element = flatperm[i];
      if (element.url == url) return element;
    }
    return null;
  }
}
