import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'can',
  pure: true,
})
export class CanAccessPipe implements PipeTransform {
  transform(
    value: any,
    code: 'id' | 'create' | 'update' | 'delete' | 'level2' | 'level1' | 'other'
  ): any {
    let connUser = JSON.parse(localStorage.getItem('smt_user'));
    let permissions = JSON.parse(atob(connUser.role.roledescription));
    // console.log('====== URL');
    const url = window.location.href
      .split('/#/')[1]
      .replace('page/', '')
      .split('?')[0];
    // console.log(url);
    // console.log('====== PERMISSIONS');
    // console.log(permissions);
    // console.log('====== PERMISSIONS FLAT');
    // console.log(this.flattenPermission(permissions));
    const element = this.getPermission(
      this.flattenPermission(permissions),
      url
    );
    // console.log('Object found ==> ', element);
    //  check for full accesss
    const hasFullAccess = element['id'];
    if (hasFullAccess) return true;
    else {
      if (code == 'level2' || code == 'other') {
        if (element['level1']) {
          return true;
        }
      }
      return element[code];
    }
  }

  flattenPermission(perms: any) {
    let final = [];
    for (const permission of perms) {
      if (permission.children) {
        const { children, data } = permission;
        final.push(data);
        final.push(...children.map((child) => child.data));
      } else {
        final.push(permission.data);
      }
    }
    return final;
  }

  getPermission(flatperm: any, url: string) {
    for (let i = 0; i < flatperm.length; i++) {
      const element = flatperm[i];
      if (element.url == url) return element;
    }
    return null;
  }
}
